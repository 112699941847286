






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueDraggableResizable from 'vue-draggable-resizable'
import { defaultLogger } from '@/loggers'

@Component({
  components: {
    VueDraggableResizable,
  },
})
export default class DraggableResizable extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 352,
  }) readonly minWidth!: number

  @Prop({
    type: Number,
    required: false,
    default: 368,
  }) readonly minHeight!: number

  @Prop({
    type: Number,
    required: false,
    default: 352,
  }) readonly w!: number

  @Prop({
    type: Number,
    required: false,
    default: 620,
  }) readonly h!: number

  @Prop({
    type: Number,
    required: false,
    default: 0,
  }) readonly startX!: number

  @Prop({
    type: Number,
    required: false,
    default: 0,
  }) readonly startY!: number

@Prop({
  type: Number,
  required: false,
  default: 5999,
}) readonly z!: number

  // default window position and size
  private x = 0
  private y = 0

  private parentResizeObserver: ResizeObserver | null = null

  @Watch('startX', { immediate: true })
  watchStartX () {
    this.readjustPosition()
  }

  @Watch('startY', { immediate: true })
  watchStartY () {
    this.readjustPosition()
  }

  private created () {
    this.x = this.startX
    this.y = this.startY
    this.parentResizeObserver = new ResizeObserver(this.readjustPosition)
  }

  private mounted () {
    this.readjustPosition()
    this.attachParentResizeObserver()
  }

  private beforeDestroy (): void {
    if (this.parentResizeObserver) {
      this.parentResizeObserver.disconnect()
      this.parentResizeObserver = null
    }
  }

  /**
   * This defines the starting position for callbar on the screen.
   * In this case - bottom right corner.
   * This is a wacky-hacky workaround for vue-draggable-resizable requiring a
   * number to be passed in for X and Y props.
   * Default - 100, just a touch off of the top left corner. Arbitrary.
   * TODO: make it remember previous position and restore it.
   */
  private readjustPosition (): void {
    defaultLogger.log('Readjusting draggable window position...')

    const p = this.$el?.parentElement
    if (!p) {
      defaultLogger.error('Can not readjust position: no parent element.')
      return
    }
    const width = p.offsetWidth
    this.x = width ? (width - this.w - this.startX) : 100

    const height = p.offsetHeight
    this.y = height ? (height - this.h - this.startY) : 100

    defaultLogger.debug('Readjusted position to:', this.x, this.y)
  }

  private attachParentResizeObserver (): void {
    defaultLogger.log('Attaching draggable parent resize observer...')

    const o = this.parentResizeObserver
    if (!o) {
      defaultLogger.error('No resize observer in mounted. Could be problems with positioning.')
      return
    }

    const p = this.$el?.parentElement
    if (!p) {
      defaultLogger.error('No parent element in mounted. Can not attach observer.')
      return
    }
    o.observe(p)
  }
}
