import { render, staticRenderFns } from "./DraggableResizable.vue?vue&type=template&id=43bf9e80&scoped=true&"
import script from "./DraggableResizable.vue?vue&type=script&lang=ts&"
export * from "./DraggableResizable.vue?vue&type=script&lang=ts&"
import style0 from "./DraggableResizable.vue?vue&type=style&index=0&id=43bf9e80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43bf9e80",
  null
  
)

export default component.exports